<template>
  <div class="flex flex-col min-h-screen bg-gray-50 text-gray-800">
    <header class="flex items-center justify-between px-6 py-4 bg-amber-200 border-b border-gray-200 shadow-sm">
      <button @click="toggleMobileSidebar"
        class="block md:hidden text-gray-500 hover:text-gray-700 focus:outline-none mr-4">
        <font-awesome-icon :icon="mobileSidebarOpen ? 'times' : 'bars'" size="lg" />
      </button>
      <div class="flex items-center">
        <img src="https://i.ibb.co/RpzLpPm/Whats-App-Image-2024-05-07-at-22-14-13-removebg-preview.png" alt="Logo"
          class="h-8 w-auto mr-3" />
        <h1 class="text-lg font-semibold">Painel Administrador</h1>
      </div>
      <nav>
        <router-link to="/login">
          <button @click="logout"
            class="inline-flex items-center px-4 py-2 bg-red-500 text-white text-sm font-medium rounded-md hover:bg-red-600 transition-colors">
            <font-awesome-icon icon="sign-out-alt" class="mr-2" />
            Sair
          </button>
        </router-link>
      </nav>
    </header>

    <div class="flex flex-1">
      <aside class="hidden md:flex md:flex-col w-64 bg-white border-r border-gray-200 py-6 px-4 flex-shrink-0">
        <div class="mb-8">
          <h2 class="font-bold text-gray-600 uppercase tracking-wider text-sm">
            Painel de Controle
          </h2>
        </div>
        <nav class="flex-1 space-y-1">
          <router-link to="/dashboard"
            class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-amber-50 hover:text-amber-600 transition-colors"
            active-class="bg-amber-100 text-amber-600">
            <font-awesome-icon icon="home" class="mr-3" />
            Dashboard
          </router-link>

          <router-link to="/dashboard/create"
            class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-amber-50 hover:text-amber-600 transition-colors"
            active-class="bg-amber-100 text-amber-600">
            <font-awesome-icon :icon="['fas', 'plus-circle']" class="mr-3" />
            Cadastrar Imóvel
          </router-link>

          <router-link to="/dashboard/view"
            class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-amber-50 hover:text-amber-600 transition-colors"
            active-class="bg-amber-100 text-amber-600">
            <font-awesome-icon icon="search" class="mr-3" />
            Visualizar Imóveis
          </router-link>

          <router-link to="/dashboard/client"
            class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-amber-50 hover:text-amber-600 transition-colors"
            active-class="bg-amber-100 text-amber-600">
            <font-awesome-icon icon="inbox" class="mr-3" />
            Cadastro de Leads
          </router-link>

          <router-link to="/dashboard/clientview"
            class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-amber-50 hover:text-amber-600 transition-colors"
            active-class="bg-amber-100 text-amber-600">
            <font-awesome-icon icon="inbox" class="mr-3" />
            Leads Cadastrados
          </router-link>

          <router-link to="/dashboard/leads"
            class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-amber-50 hover:text-amber-600 transition-colors"
            active-class="bg-amber-100 text-amber-600">
            <font-awesome-icon icon="inbox" class="mr-3" />
            Leads Externos
          </router-link>

          <router-link to="/dashboard/register"
            class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-amber-50 hover:text-amber-600 transition-colors"
            active-class="bg-amber-100 text-amber-600">
            <font-awesome-icon icon="user-plus" class="mr-3" />
            Cadastrar Usuário
          </router-link>

          <router-link to="/dashboard/users"
            class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-amber-50 hover:text-amber-600 transition-colors"
            active-class="bg-amber-100 text-amber-600">
            <font-awesome-icon icon="users" class="mr-3" />
            Gerenciar Usuários
          </router-link>
        </nav>
      </aside>

      <transition name="slide">
        <aside v-if="mobileSidebarOpen"
          class="fixed inset-y-0 left-0 z-50 w-64 bg-white border-r border-gray-200 py-6 px-4 flex flex-col md:hidden">
          <div class="flex items-center justify-between mb-8">
            <h2 class="font-bold text-gray-600 uppercase tracking-wider text-sm">
              Painel de Controle
            </h2>
            <button @click="toggleMobileSidebar" class="text-gray-500 hover:text-gray-700 focus:outline-none">
              <font-awesome-icon icon="times" size="lg" />
            </button>
          </div>
          <nav class="flex-1 space-y-1">
            <router-link to="/dashboard"
              class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-amber-50 hover:text-amber-600 transition-colors"
              active-class="bg-amber-100 text-amber-600" @click="toggleMobileSidebar">
              <font-awesome-icon icon="home" class="mr-3" />
              Dashboard
            </router-link>

            <router-link to="/dashboard/create"
              class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-amber-50 hover:text-amber-600 transition-colors"
              active-class="bg-amber-100 text-amber-600" @click="toggleMobileSidebar">
              <font-awesome-icon :icon="['fas', 'plus-circle']" class="mr-3" />
              Cadastrar Imóvel
            </router-link>

            <router-link to="/dashboard/view"
              class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-amber-50 hover:text-amber-600 transition-colors"
              active-class="bg-amber-100 text-amber-600" @click="toggleMobileSidebar">
              <font-awesome-icon icon="search" class="mr-3" />
              Visualizar Imóveis
            </router-link>

            <router-link to="/dashboard/client"
              class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-amber-50 hover:text-amber-600 transition-colors"
              active-class="bg-amber-100 text-amber-600" @click="toggleMobileSidebar">
              <font-awesome-icon icon="inbox" class="mr-3" />
              Cadastro de Leads
            </router-link>

            <router-link to="/dashboard/clientview"
              class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-amber-50 hover:text-amber-600 transition-colors"
              active-class="bg-amber-100 text-amber-600" @click="toggleMobileSidebar">
              <font-awesome-icon icon="inbox" class="mr-3" />
              Leads Cadastrados
            </router-link>

            <router-link to="/dashboard/leads"
              class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-amber-50 hover:text-amber-600 transition-colors"
              active-class="bg-amber-100 text-amber-600" @click="toggleMobileSidebar">
              <font-awesome-icon icon="inbox" class="mr-3" />
              Leads Externos
            </router-link>

            <router-link to="/dashboard/register"
              class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-amber-50 hover:text-amber-600 transition-colors"
              active-class="bg-amber-100 text-amber-600" @click="toggleMobileSidebar">
              <font-awesome-icon icon="user-plus" class="mr-3" />
              Cadastrar Usuário
            </router-link>

            <router-link to="/dashboard/users"
              class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-amber-50 hover:text-amber-600 transition-colors"
              active-class="bg-amber-100 text-amber-600" @click="toggleMobileSidebar">
              <font-awesome-icon icon="users" class="mr-3" />
              Gerenciar Usuários
            </router-link>
          </nav>
        </aside>
      </transition>

      <main class="flex-grow p-6">
        <router-view />
      </main>
    </div>

    <footer class="bg-amber-200 border-t border-gray-200 py-4 text-center text-sm text-gray-500">
      © 2024 Alvaro Motta Imóveis. Todos os direitos reservados.
    </footer>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import authService from '@/service/authService'

const router = useRouter()
const mobileSidebarOpen = ref(false)

function toggleMobileSidebar() {
  mobileSidebarOpen.value = !mobileSidebarOpen.value
}

function logout() {
  authService.logout()
  router.push('/login')
}
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(-100%);
}
</style>
