import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router/index.js'
import '../src/css/main.css'
import axios from 'axios'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
    faSignOutAlt,
    faTimes,
    faBars,
    faHome,
    faPlusCircle,
    faSearch,
    faInbox,
    faUserPlus,
    faUsers
} from '@fortawesome/free-solid-svg-icons'

library.add(
    faSignOutAlt,
    faTimes,
    faBars,
    faHome,
    faPlusCircle,
    faSearch,
    faInbox,
    faUserPlus,
    faUsers
)

axios.defaults.baseURL = 'https://alvaro.chatease.app'
axios.interceptors.request.use(
    (config) => {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user && user.accessToken) {
            config.headers.Authorization = 'Bearer ' + user.accessToken
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .use(router)
    .mount('#app')
